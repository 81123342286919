@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}
nav{
    height: 70px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 40px;
    margin-bottom: 10px;
}
nav .logo img{
    width: 50px;
    height: 50px;
}

nav .menu{
    width: 300px;
    display: flex;
    justify-content: space-evenly;
}
nav .menu a{
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    color: rgb(35, 35, 189);
}

main{
    width: 1000px;
    height: fit-content;
    margin-top: 0px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    display: flex;
    justify-content: space-evenly;
    
}

.eventPic{
    width: 400px;
    height: auto;
    border-radius:  20px;
}

.containt{
    width: 500px;
    /* background-color: #5e1; */
    padding: 30px;
}


.containt h2{
    margin-bottom: 30px;
    font-family: 'Montserrat', sans-serif;
}
.containt p{
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 5px;
}
.containt p span{
    
    margin-right: 20px;
    color: #3B4CAB;
}
.containt p{
    margin-top: 20px;
}
.containt button{
    margin-top: 20px;
    width: 100%;
    height: 40px;
    border: solid 1px #438 ;
    font-family: 'Montserrat', sans-serif;
    font: bold;
    font-size: medium;
    background-color:#3B4CAB;
    color: #fff    ;
}

.hash{
    color: #3B4CAB;
}
.username{
    color: #3B4CAB;
}

.contact{
    margin-top: 30px;
    text-align: center;
}
.contact span{
    color: #3B4CAB;
}

@media screen and (max-width: 940px) {
    .eventPic {
        width: 45vw;
        height: auto;
        border-radius: 20px;
    }
    .containt{
        padding-right: 0px;
        width: 40vw;
    }
    main{
        width: 100vw;
        padding: 30px;
    }
}


@media screen and (max-width: 700px) {
    .eventPic {
        width: 100vw;
        height: auto;
        margin-top: 0px;
        border-radius: 0px;
    }
    .containt{
        margin-top: -20px;
        border-radius: 20px;
        width: 100vw;
        background-color: #fff;
        padding: 30px;
    }
    main{
        position: relative;
        top: 0px;
        left: 0px;
        transform: translate(0px , 0px);
        flex-direction: column;
        padding: 0;
    }
    .containt button{
        width: calc(100% - 30px);
    }
    nav{
        padding: 10px;
    }
}


@media screen and (max-width: 450px) {
    .eventPic{
        width: 100vw;
        height: auto;
        border-radius:  0px;
      
        
    }
    main .containt{
        background-color: #fff;
        margin-top: -20px;
        width: 100vw;
        height: fit-content;
    }
    main{
        margin-top: 0px;
        top: 0px;
        left: 0px;
        transform: translate(0px , 0px);
        height: fit-content;
        margin-bottom: 50vh;
        flex-direction: column;
        align-items: center;
    }
    nav{
        padding: 10px;
    }
}

