@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

/* nav  */
nav {
  height: 70px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
}
nav .logo img {
  width: 50px;
  height: 50px;
}
nav .menu {
  width: 100px;
  display: flex;
  justify-content: center;
}
nav .menu a {
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  color: rgb(35, 35, 189);
}

/* view port 1  */
.viewport1{
  width: 100vw;
  height: 100vh ;
  background-color: rgb(191, 150, 64);
  background-size: cover;
  color: rgb(0, 0, 0);
  min-height: 600px;
 
}
.viewport1 .bgi{
  width: 100vw;
  height: 100vh;
  position: fixed;
 background-color: #fff;
 overflow: hidden;
 z-index: 0;
}
.viewport1 .bgi img{
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: brightness(30%) saturate(200%);
}
.viewport1 .center{
  width: 700px;
  height: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}
.viewport1 .center .heading{
  margin-top: 40px;
}
.viewport1 .center .info{
  margin-top: 40px;
}
.viewport1 .center .btn{
  margin-top: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #fff0;
}
.viewport1 .center .btn .myButton {
	background-color:transparent;
	border:1px solid #fff;
	display:inline-block;
	cursor:pointer;
	color:#fff;
	font-family:Arial;
	font-size:16px;
	padding:13px 50px;
	text-decoration:none;
	
}
.myButton:active {
	position:relative;
	top:1px;
}

.viewport2{
  width: 100vw;
  height: 600px ;
  color: rgb(0, 0, 0);
  z-index: -1;
}

.viewport2 .center{
  width: 100vw;
  min-height: 600px;
  max-height: 663px;
  height: 100vh;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.viewport2 .heading{
  margin-top: 100px;

}
.viewport2 .info{
  margin: 40px 0px;
  padding: 0 20px;
  width: 70vw;
  min-width: 350px;
  max-width: 750px;
}

.things3{
  width: 100vw;
  height: 425px;
  background-color: #FBB417;
  display: flex;
  justify-content: space-evenly ;
  align-items: center; 
}
.things3 div {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}
.things3 div span{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: #000000;
  color:  #fff;
  font-size: 40px ;
  border-radius: 50%;
  margin-bottom: 30px;
}
.things3 .head{
  
  margin-bottom: 20px;
}










.viewport3{
  width: 100vw;
  height: 300px ;
  color: rgb(0, 0, 0);
  z-index: -1;
}

.viewport3 .center{
  width: 100vw;
  min-height: 300px;
  max-height: 303px;
  height: 100vh;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.viewport3 .heading{
  margin-top: 100px;

}
.viewport3 .info{
  margin: 40px 0px;
  padding: 0 20px;
  width: 70vw;
  min-width: 350px;
  max-width: 750px;
}


.viewport3 .links {
  width: 300px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
}
.viewport3 .links a{
  text-decoration: none;
  
}

.viewport3 .links img{
  width: 40px;
}



@media screen and (max-width: 450px) {
  .viewport1{
    width: 100vw;
    height: 100vh ;
    background-color: rgb(191, 150, 64);
    background-size: cover;
    color: rgb(0, 0, 0);
    min-height: 600px;
   
  }
  .viewport1 .bgi{
    width: 100vw;
    height: 100vh;
    position: fixed;
   background-color: #fff;
   overflow: hidden;
   z-index: 0;
  }
  .viewport1 .bgi img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: brightness(30%) saturate(200%);
  }
  .viewport1 .center{
    width: 90vw;
    height: 300px;
    justify-content: space-evenly;
  }
  .viewport1 .center .heading{
    margin-top: 40px;
  }
  .viewport1 .center .info{
    margin-top: 40px;
  }
  .viewport1 .center .btn{
    margin-top: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff0;
  }
  .viewport1 .center .btn .myButton {
    background-color: transparent;
    border:1px solid #fff;
    display:inline-block;
    cursor:pointer;
    color: #fff;
    font-family:Arial;
    font-size:14px;
    padding:10px 40px;
    text-decoration:none;
    
  }
  .myButton:active {
    position:relative;
    top:1px;
  }
}

