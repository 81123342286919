@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

/* nav  */
nav {
  height: 70px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
}
nav .home_logo__snd2X img {
  width: 50px;
  height: 50px;
}
nav .home_menu__pIMAD {
  width: 100px;
  display: flex;
  justify-content: center;
}
nav .home_menu__pIMAD a {
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  color: rgb(35, 35, 189);
}

/* view port 1  */
.home_viewport1__1cATy{
  width: 100vw;
  height: 100vh ;
  background-color: rgb(191, 150, 64);
  background-size: cover;
  color: rgb(0, 0, 0);
  min-height: 600px;
 
}
.home_viewport1__1cATy .home_bgi__14DPx{
  width: 100vw;
  height: 100vh;
  position: fixed;
 background-color: #fff;
 overflow: hidden;
 z-index: 0;
}
.home_viewport1__1cATy .home_bgi__14DPx img{
  object-fit: cover;
  width: 100%;
  height: 100%;
  -webkit-filter: brightness(30%) saturate(200%);
          filter: brightness(30%) saturate(200%);
}
.home_viewport1__1cATy .home_center__1UgMh{
  width: 700px;
  height: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}
.home_viewport1__1cATy .home_center__1UgMh .home_heading__aqkjo{
  margin-top: 40px;
}
.home_viewport1__1cATy .home_center__1UgMh .home_info__1beOB{
  margin-top: 40px;
}
.home_viewport1__1cATy .home_center__1UgMh .home_btn__1LpZK{
  margin-top: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #fff0;
}
.home_viewport1__1cATy .home_center__1UgMh .home_btn__1LpZK .home_myButton__1qXk8 {
	background-color:transparent;
	border:1px solid #fff;
	display:inline-block;
	cursor:pointer;
	color:#fff;
	font-family:Arial;
	font-size:16px;
	padding:13px 50px;
	text-decoration:none;
	
}
.home_myButton__1qXk8:active {
	position:relative;
	top:1px;
}

.home_viewport2__3Wbvr{
  width: 100vw;
  height: 600px ;
  color: rgb(0, 0, 0);
  z-index: -1;
}

.home_viewport2__3Wbvr .home_center__1UgMh{
  width: 100vw;
  min-height: 600px;
  max-height: 663px;
  height: 100vh;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.home_viewport2__3Wbvr .home_heading__aqkjo{
  margin-top: 100px;

}
.home_viewport2__3Wbvr .home_info__1beOB{
  margin: 40px 0px;
  padding: 0 20px;
  width: 70vw;
  min-width: 350px;
  max-width: 750px;
}

.home_things3__1_Gfg{
  width: 100vw;
  height: 425px;
  background-color: #FBB417;
  display: flex;
  justify-content: space-evenly ;
  align-items: center; 
}
.home_things3__1_Gfg div {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}
.home_things3__1_Gfg div span{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: #000000;
  color:  #fff;
  font-size: 40px ;
  border-radius: 50%;
  margin-bottom: 30px;
}
.home_things3__1_Gfg .home_head__17ETb{
  
  margin-bottom: 20px;
}










.home_viewport3__14R10{
  width: 100vw;
  height: 300px ;
  color: rgb(0, 0, 0);
  z-index: -1;
}

.home_viewport3__14R10 .home_center__1UgMh{
  width: 100vw;
  min-height: 300px;
  max-height: 303px;
  height: 100vh;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.home_viewport3__14R10 .home_heading__aqkjo{
  margin-top: 100px;

}
.home_viewport3__14R10 .home_info__1beOB{
  margin: 40px 0px;
  padding: 0 20px;
  width: 70vw;
  min-width: 350px;
  max-width: 750px;
}


.home_viewport3__14R10 .home_links__1QSDX {
  width: 300px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
}
.home_viewport3__14R10 .home_links__1QSDX a{
  text-decoration: none;
  
}

.home_viewport3__14R10 .home_links__1QSDX img{
  width: 40px;
}



@media screen and (max-width: 450px) {
  .home_viewport1__1cATy{
    width: 100vw;
    height: 100vh ;
    background-color: rgb(191, 150, 64);
    background-size: cover;
    color: rgb(0, 0, 0);
    min-height: 600px;
   
  }
  .home_viewport1__1cATy .home_bgi__14DPx{
    width: 100vw;
    height: 100vh;
    position: fixed;
   background-color: #fff;
   overflow: hidden;
   z-index: 0;
  }
  .home_viewport1__1cATy .home_bgi__14DPx img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    -webkit-filter: brightness(30%) saturate(200%);
            filter: brightness(30%) saturate(200%);
  }
  .home_viewport1__1cATy .home_center__1UgMh{
    width: 90vw;
    height: 300px;
    justify-content: space-evenly;
  }
  .home_viewport1__1cATy .home_center__1UgMh .home_heading__aqkjo{
    margin-top: 40px;
  }
  .home_viewport1__1cATy .home_center__1UgMh .home_info__1beOB{
    margin-top: 40px;
  }
  .home_viewport1__1cATy .home_center__1UgMh .home_btn__1LpZK{
    margin-top: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff0;
  }
  .home_viewport1__1cATy .home_center__1UgMh .home_btn__1LpZK .home_myButton__1qXk8 {
    background-color: transparent;
    border:1px solid #fff;
    display:inline-block;
    cursor:pointer;
    color: #fff;
    font-family:Arial;
    font-size:14px;
    padding:10px 40px;
    text-decoration:none;
    
  }
  .home_myButton__1qXk8:active {
    position:relative;
    top:1px;
  }
}


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}
nav{
    height: 70px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 40px;
    margin-bottom: 10px;
}
nav .detail_logo__1URre img{
    width: 50px;
    height: 50px;
}

nav .detail_menu__5b72F{
    width: 300px;
    display: flex;
    justify-content: space-evenly;
}
nav .detail_menu__5b72F a{
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    color: rgb(35, 35, 189);
}

main{
    width: 1000px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 0px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    display: flex;
    justify-content: space-evenly;
    
}

.detail_eventPic__xsIt4{
    width: 400px;
    height: auto;
    border-radius:  20px;
}

.detail_containt__1BZxl{
    width: 500px;
    /* background-color: #5e1; */
    padding: 30px;
}


.detail_containt__1BZxl h2{
    margin-bottom: 30px;
    font-family: 'Montserrat', sans-serif;
}
.detail_containt__1BZxl p{
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 5px;
}
.detail_containt__1BZxl p span{
    
    margin-right: 20px;
    color: #3B4CAB;
}
.detail_containt__1BZxl p{
    margin-top: 20px;
}
.detail_containt__1BZxl button{
    margin-top: 20px;
    width: 100%;
    height: 40px;
    border: solid 1px #438 ;
    font-family: 'Montserrat', sans-serif;
    font: bold;
    font-size: medium;
    background-color:#3B4CAB;
    color: #fff    ;
}

.detail_hash__xIEct{
    color: #3B4CAB;
}
.detail_username__U7qqM{
    color: #3B4CAB;
}

.detail_contact__1T6MP{
    margin-top: 30px;
    text-align: center;
}
.detail_contact__1T6MP span{
    color: #3B4CAB;
}

@media screen and (max-width: 940px) {
    .detail_eventPic__xsIt4 {
        width: 45vw;
        height: auto;
        border-radius: 20px;
    }
    .detail_containt__1BZxl{
        padding-right: 0px;
        width: 40vw;
    }
    main{
        width: 100vw;
        padding: 30px;
    }
}


@media screen and (max-width: 700px) {
    .detail_eventPic__xsIt4 {
        width: 100vw;
        height: auto;
        margin-top: 0px;
        border-radius: 0px;
    }
    .detail_containt__1BZxl{
        margin-top: -20px;
        border-radius: 20px;
        width: 100vw;
        background-color: #fff;
        padding: 30px;
    }
    main{
        position: relative;
        top: 0px;
        left: 0px;
        transform: translate(0px , 0px);
        flex-direction: column;
        padding: 0;
    }
    .detail_containt__1BZxl button{
        width: calc(100% - 30px);
    }
    nav{
        padding: 10px;
    }
}


@media screen and (max-width: 450px) {
    .detail_eventPic__xsIt4{
        width: 100vw;
        height: auto;
        border-radius:  0px;
      
        
    }
    main .detail_containt__1BZxl{
        background-color: #fff;
        margin-top: -20px;
        width: 100vw;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    main{
        margin-top: 0px;
        top: 0px;
        left: 0px;
        transform: translate(0px , 0px);
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin-bottom: 50vh;
        flex-direction: column;
        align-items: center;
    }
    nav{
        padding: 10px;
    }
}


